<template>
  <div id="documents-list">
    <v-dialog v-model="dialog" eager max-width="940" :persistent="dialogPersistent">
      <v-card>
        <v-card-title v-if="dialogPersistent">
          <div class="pb-2 d-flex align-center justify-space-between" style="width: 100%">
            <div>
              <div v-if="$vuetify.breakpoint.smAndUp">
                <v-icon large class="mr-2">
                  {{ icons.mdiDownload }}
                </v-icon>
                Belgeyi İndir
              </div>
            </div>
            <div>
              <span class="text-body-2 font-weight-bold mr-3">Doküman Numarası : </span>
              <v-chip label color="secondary">
                <span class="text-overline">{{ dialogNum }}</span>
              </v-chip>
            </div>
          </div>
          <v-progress-linear indeterminate></v-progress-linear>
        </v-card-title>
        <v-card-title v-else>
          <div>
            <v-btn @click="belgeIndir()" color="secondary">
              <v-icon class="mr-2 white--text">
                {{ icons.mdiDownload }}
              </v-icon>
              Belgeyi Yazdır / İndir
            </v-btn>
          </div>

          <v-spacer />
          <v-btn color="error" depressed @click="dialog = false">
            Kapat
            <v-icon right>
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <div id="download"></div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDetail" eager max-width="1400" :persistent="dialogPersistent">
      <v-card>
        <v-card-title v-if="dialogPersistent">
          <div class="pb-2 d-flex align-center justify-space-between" style="width: 100%">
            <div>
              <div v-if="$vuetify.breakpoint.smAndUp">
                <v-icon large class="mr-2">
                  {{ icons.mdiEye }}
                </v-icon>
                Ayrıntılar
              </div>
            </div>
            <div>
              <span class="text-body-2 font-weight-bold mr-3">Doküman Numarası : </span>
              <v-chip label color="secondary">
                <span class="text-overline">{{ dialogNum }}</span>
              </v-chip>
            </div>
          </div>
          <v-progress-linear indeterminate></v-progress-linear>
        </v-card-title>
        <v-card-title v-else>
          <download-excel :data="siparisListesi_json" :fields="siparisListesi_jsonFields" name="TABLO__ANLAS.xls">
            <v-btn color="secondary">
              <v-icon color="white" large left>
                {{ icons.mdiDownload }}
              </v-icon>
              <div class="white--text">İndir</div>
            </v-btn>
          </download-excel>

          <v-spacer />
          <v-btn color="error" depressed @click="dialogDetail = false">
            Kapat
            <v-icon right>
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text v-if="!dialogPersistent" class="pa-0">
          <v-data-table :headers="detailHeaders" :items="detailItems" :items-per-page="-1" hide-default-footer>
            <template #item.QUANTITY="{ item }">
              <span> {{ item.QUANTITY }} </span>
            </template>

            <template #[`item.sevkmiktar`]="{ item }">
              {{ item.QUANTITY - item.AVAILQTY }}
            </template>
            <template #[`item.AVAILQTY`]="{ item }">
              <v-chip :color="item.AVAILQTY != 0 ? 'error' : null"
                ><div :class="item.AVAILQTY != 0 ? 'white--text' : null">
                  {{ item.AVAILQTY }}
                </div></v-chip
              >
            </template>

            <template #item.SPRICE="{ item }">
              <span> {{ item.SPRICE | currency }} </span>
            </template>
            <template #item.SUBTOTAL="{ item }">
              <span> {{ item.SUBTOTAL | currency }} </span>
            </template>
            <template #item.VATAMNT="{ item }">
              <span> {{ item.VATAMNT | currency }} </span>
            </template>
            <template #item.GRANDTOTAL="{ item }">
              <span> {{ item.GRANDTOTAL | currency }} </span>
            </template>
            <template #item.CURRENCY="{ item }">
              <span> {{ item.CURRENCY | currency }} </span>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-navigation-drawer
      v-if="!status"
      :value="isFilterUserSidebarActive"
      temporary
      touchless
      floating
      stateless
      :right="!$vuetify.rtl"
      :width="$vuetify.breakpoint.smAndUp ? 350 : '100%'"
      app
      :class="$vuetify.breakpoint.smAndUp ? 'rounded-tl-xl rounded-bl-xl' : null"
    >
      <v-sheet :color="isDark ? '#363351' : '#fafafa'" class="pa-3 d-flex align-center justify-space-between">
        <div class="d-flex align-center">
          <v-avatar size="48" color="secondary">
            <v-icon size="24" class="white--text">
              {{ icons.mdiFilter }}
            </v-icon>
          </v-avatar>
          <div class="text-h6 font-weight-medium ml-3">Filtreleme</div>
        </div>
        <v-btn icon x-small @click="isFilterUserSidebarActive = false">
          <v-icon> {{ icons.mdiClose }}</v-icon>
        </v-btn>
      </v-sheet>
      <div @keyup.enter="fetchDocuments()">
        <v-menu
          v-model="startMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="filter.startDate"
              label="Başlangıç Tarihi"
              hide-details=""
              readonly
              filled
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="filter.startDate" no-title @input="startMenu = false"></v-date-picker>
        </v-menu>
        <v-menu
          v-model="endMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="filter.endDate"
              label="Bitiş Tarihi"
              hide-details=""
              readonly
              filled
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="filter.endDate" no-title @input="endMenu = false"></v-date-picker>
        </v-menu>

        <v-select
          v-if="docTypes !== 1"
          v-model="filter.docStatuses"
          label="Durum"
          class="rounded-0"
          filled
          hide-details
          :menu-props="{ bottom: true, offsetY: true }"
          :items="docStatusesItems"
        ></v-select>

        <v-text-field v-model="filter.docCustomer" class="rounded-0" filled hide-details label="Müşteri"></v-text-field>
        <v-text-field v-model="filter.docMaterial" class="rounded-0" filled hide-details label="Malzeme"></v-text-field>
      </div>

      <template v-slot:append>
        <v-container>
          <v-row justify="center" align="center">
            <v-col cols="12">
              <v-btn x-large block rounded class="text-capitalize" color="secondary" @click="fetchDocuments()">
                Filitrele
              </v-btn>
            </v-col>
          </v-row>
        </v-container>

        <!-- <div class="pa-3">
          <v-btn x-large rounded block class="text-capitalize" color="secondary" @click="fetchDocuments()">
            Tamam
          </v-btn>
        </div> -->
      </template>
    </v-navigation-drawer>

    <customer-list v-if="userData.role === 'PLSYR' && $vuetify.breakpoint.smAndDown" />

    <v-alert v-if="userData.role === 'PLSYR' && !$store.state.plasiyerCustomer" tile type="warning">
      Lütfen yukarıdan bir müşteri seçin
    </v-alert>

    <div v-else>
      <v-row dense>
        <v-col>
          <v-expand-transition>
            <v-card
              v-if="status"
              :elevation="$vuetify.breakpoint.smAndDown ? '0' : '3'"
              class="mx-auto mt-md-9"
              :color="$vuetify.breakpoint.smAndDown ? 'transparent' : null"
              :max-width="$vuetify.breakpoint.smAndDown ? '92%' : 600"
            >
              <v-img
                class="white--text"
                src="@/assets/images/pages/header-01.jpeg"
                :height="$vuetify.breakpoint.smAndDown ? 100 : 100"
              >
                <div class="pdng card-img--text card-img--text-type-1">
                  <div class="text-h6 font-weight-medium">
                    <v-icon large left color="white">
                      {{ icons.mdiNoteText }}
                    </v-icon>
                    {{ title }}
                  </div>

                  <p class="text-body-2 white--text font-weight-thin" v-text="msg" />
                  <v-progress-linear v-show="loading" color="white" indeterminate></v-progress-linear>
                </div>
              </v-img>
              <v-expand-transition>
                <div v-show="!loading">
                  <v-card-text class="pa-0 pt-6">
                    <v-row :class="!$vuetify.breakpoint.smAndDown ? 'pt-6' : null">
                      <v-col cols="12" md="6" class="pl-md-9 pr-md-2 px-9">
                        <v-menu
                          v-model="startMenu"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="filter.startDate"
                              label="Başlangıç Tarihi"
                              hide-details=""
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker v-model="filter.startDate" no-title @input="startMenu = false"></v-date-picker>
                        </v-menu>
                      </v-col>

                      <v-col cols="12" md="6" class="pl-md-2 pr-md-9 px-9">
                        <v-menu
                          v-model="endMenu"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="filter.endDate"
                              label="Bitiş Tarihi"
                              hide-details
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker v-model="filter.endDate" no-title @input="endMenu = false"></v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12" md="6" class="pl-md-9 pr-md-2 px-9">
                        <v-select
                          v-model="filter.docStatuses"
                          label="Durum"
                          hide-details=""
                          :items="docStatusesItems"
                        ></v-select>
                      </v-col>

                      <v-col cols="12" md="6" class="pl-md-2 pr-md-9 px-9">
                        <v-text-field v-model="filter.docMaterial" hide-details label="Malzeme"></v-text-field>
                      </v-col>
                      <v-col v-if="userData.role === 'PLSYR'" cols="12" :md="12" class="px-9">
                        <!--
                        <v-autocomplete
                          v-model="filter.docCustomer"
                          label="Müşteri"
                          :items="customerItems"
                          clearable
                          hide-details
                          item-text="name1"
                          item-value="customer"
                        ></v-autocomplete>
                        <pre></pre>
                       <v-row class="pt-6">
                      <v-col v-if="userData.role === 'PLSYR'" cols="12" class="px-9">

                      </v-col>
                    </v-row>
                    <v-divider v-if="!$vuetify.breakpoint.smAndDown && userData.role === 'PLSYR'"></v-divider> -->
                      </v-col>
                    </v-row>
                  </v-card-text>

                  <v-card-actions class="pb-9 pt-9">
                    <v-spacer />
                    <v-btn
                      color="tertiary"
                      class="text-capitalize white--text text-body-1"
                      tile
                      x-large
                      @click="fetchDocuments()"
                    >
                      Ara
                    </v-btn>
                  </v-card-actions>
                </div>
              </v-expand-transition>
            </v-card>
          </v-expand-transition>

          <v-card
            v-if="!status"
            tile
            :flat="$vuetify.breakpoint.mdAndDown"
            :class="$vuetify.breakpoint.mdAndDown ? '' : ' rounded-t-lg'"
          >
            <v-toolbar
              :color="$vuetify.breakpoint.mdAndDown ? 'transparent' : 'transparent'"
              :flat="!$vuetify.breakpoint.smAndDown"
              :height="$vuetify.breakpoint.smAndDown ? 57 : 87"
            >
              <v-toolbar-title class="text-body-2 text-md-h6 font-weight-medium">
                <v-icon :large="!$vuetify.breakpoint.smAndDown" left>
                  {{ icons.mdiFileDocument }}
                </v-icon>
                {{ title }}
              </v-toolbar-title>

              <v-spacer></v-spacer>

              <download-excel
                v-if="!$vuetify.breakpoint.smAndDown"
                :data="json_data"
                :fields="json_fields"
                name="SATIS_BELGELERI__ANLAS.xls"
              >
                <v-btn plain text small>
                  <v-icon left>
                    {{ icons.mdiDownload }}
                  </v-icon>
                  İndir
                </v-btn>
              </download-excel>

              <v-btn plain text small @click.stop="isFilterUserSidebarActive = !isFilterUserSidebarActive">
                <v-icon left>
                  {{ icons.mdiFilter }}
                </v-icon>
                Filitre
              </v-btn>
            </v-toolbar>
            <pre>{{ docTypes.value }}</pre>
            <!-- table -->
            <v-data-table
              v-model="selectedRows"
              :headers="tableColumns"
              :items="tableItems"
              :options.sync="options"
              :items-per-page="-1"
              fixed-header
              :height="dataTableHeight"
              :loading="loading"
            >
              <template #[`item.adres`]="{ item }">
                {{ item.GRCADRLINE1 }} - {{ item.GRCADRLINE2 }} - {{ item.GRCCITY }} - {{ item.NAME1 }}
              </template>

              <template #[`item.STATX`]="{ item }">
                <v-chip :color="STATXcolor(item.STATX)" label small>
                  {{ item.STATX }}
                </v-chip>
              </template>
              <template #[`item.DOCNUM`]="{ item }">
                <v-chip color="chip" label small>
                  {{ item.DOCNUM }}
                </v-chip>
              </template>
              <template #[`item.PRINTEDINVNUM`]="{ item }">
                <v-chip color="chip" label small> {{ item.PRINTEDINVTYPE }} {{ item.PRINTEDINVNUM }} </v-chip>
              </template>

              <template #[`item.PRINTEDDELNUM`]="{ item }">
                <v-chip color="chip" label small> {{ item.PRINTEDDELTYPE }} {{ item.PRINTEDDELNUM }} </v-chip>
              </template>

              <template #[`item.DOCSTATE`]="{ item }">
                <v-chip :color="item.DOCSTATECOLOR" label small>
                  {{ item.DOCSTATE }}
                </v-chip>
              </template>
              <template #[`item.VALIDFROM`]="{ item }">
                <div class="text-caption font-weight-medium ls-0">
                  {{ item.VALIDFROM.slice(0, 10) }}
                </div>
              </template>
              <template #[`item.SUBTOTAL`]="{ item }">
                <div class="text-caption font-weight-medium">
                  {{ item.SUBTOTAL | currency }}
                </div>
              </template>
              <template #[`item.GROSS`]="{ item }">
                <div class="secondary--text text-caption font-weight-medium">
                  {{ item.GROSS | currency }}
                </div>
              </template>
              <template #[`item.TOTDISCAMNT`]="{ item }">
                <div class="error--text text-caption font-weight-medium">
                  {{ item.TOTDISCAMNT | currency }}
                </div>
              </template>
              <template #[`item.GRANDTOTAL`]="{ item }">
                <div class="success--text text-caption font-weight-medium">
                  {{ item.GRANDTOTAL | currency }}
                </div>
              </template>
              <template #[`item.actions`]="{ item }">
                <div class="mb-3">
                  <!--   


                        <v-btn
                    v-if="item.B2BSIPNO"
                    class="mr-2"
                    depressed
                    x-small
                    outlined
                    :href="'./../order-tracking/view/' + item.B2BSIPNO"
                    target="_blank"
                  >
                    <v-icon small class="mr-2">
                      {{ icons.mdiEye }}
                    </v-icon>
                    <span class="mr-1">Ön İzleme</span>
                  </v-btn>
                  -->

                  <v-btn depressed x-small outlined @click="detail(item.DOCNUM, item.DOCTYPE)">
                    <v-icon small class="mr-2">
                      {{ icons.mdiEye }}
                    </v-icon>
                    <span class="mr-1">Ayrıntılar</span>
                  </v-btn>

                  <v-btn
                    depressed
                    color="nightDark"
                    x-small
                    class="ml-3"
                    @click="download(item.DOCNUM, item.DOCTYPE, item.INVDETACCESS)"
                  >
                    <v-icon small class="mr-2">
                      {{ icons.mdiDownload }}
                    </v-icon>
                    <span v-if="item.INVDETACCESS" class="error--text mr-1"> Belgeyi İndir</span>
                    <span v-else class="mr-1"> Belgeyi İndir</span>
                  </v-btn>
                </div>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <!-- <div v-else>
      <customer-list v-if="userData.role === 'PLSYR' && $vuetify.breakpoint.xsOnly" />
      <v-alert text outlined class="mx-3 mx-lg-0 rounded-pill mt-2 mt-md-0 text-caption" type="warning">
        Lütfen yukarıdan bir müşteri seçin
      </v-alert>
    </div> -->
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import CustomerList from '@/components/CustomerList.vue'
import store from '@/store'
import useAppConfig from '@core/@app-config/useAppConfig'
import { useRouter } from '@core/utils'
import { mdiClose, mdiDownload, mdiEye, mdiFileDocument, mdiFilter, mdiNoteText, mdiSend, mdiViewList } from '@mdi/js'
import { onMounted, onUnmounted, ref, watch } from '@vue/composition-api'
import Vue from 'vue'
import documentsStoreModule from './documentsStoreModule'

export default {
  components: {
    CustomerList,
  },
  setup() {
    onMounted(() => {
      window.addEventListener('keydown', keyDownHandler)
    })
    onUnmounted(() => {
      window.removeEventListener('keydown', keyDownHandler)
    })
    const keyDownHandler = event => {
      if (event.code === 'Escape') {
        emit('update:is-product-filter-sidebar-active', false)
      }
    }

    const DOCUMENTS_APP_STORE_MODULE_NAME = 'app-documents'
    const { isDark } = useAppConfig()
    const docTypesItems = ref([])
    const docTypes = ref(5)
    const isFilter = ref(0)
    const filteringStatus = ref(false)
    const docStatusesItems = ref([])
    const { route } = useRouter()
    const data1 = ref('')
    const statusPlasiyer = ref(false)
    onMounted(() => {
      //console.log('gelen data = ' + store.state.plasiyerCustomer)
      data1.value = store.state.plasiyerCustomer
      if (userData.role === 'PLSYR' && store.state.plasiyerCustomer === null) {
        statusPlasiyer.value = false
        data1.value = store.state.plasiyerCustomer
        //console.log('data1 = ' + data1.value)
      } else {
        statusPlasiyer.value = true
      }
      //console.log('x = ' + data1.value)
    })

    /*
    watch(
      () => data1.value,
      () => {
        console.log('x = ' + data1.value)
      },
    )
*/
    watch(
      () => store.state.plasiyerCustomer,
      () => {
        if (store.state.plasiyerCustomer) {
          status.value = true
          statusPlasiyer.value = true
          console.log(store.state.plasiyerCustomer)
          data1.value = store.state.plasiyerCustomer
        }
      },
    )

    // Register module
    if (!store.hasModule(DOCUMENTS_APP_STORE_MODULE_NAME)) {
      store.registerModule(DOCUMENTS_APP_STORE_MODULE_NAME, documentsStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(DOCUMENTS_APP_STORE_MODULE_NAME)) store.unregisterModule(DOCUMENTS_APP_STORE_MODULE_NAME)
    })

    onMounted(() => {
      const param = {
        method: 'getDocTypes',
      }
      store
        .dispatch('postMethod', param)
        .then(response => {
          const data = []
          // eslint-disable-next-line no-restricted-syntax
          for (const value of Object.entries(response.detail)) {
            const item = {
              text: value[1],
              value: Number(value[0]),
            }
            data.push(item)
          }

          docTypesItems.value = data
        })
        .catch(error => {
          console.log(error)
        })
    })
    const dialog = ref(false)
    const dialogPersistent = ref(true)
    let iframe
    watch(dialog, val => {
      if (val) {
        iframe = document.createElement('iframe')
      } else {
        iframe.remove()
      }
    })
    const dialogNum = ref('')
    const belgeIndir = () => {
      document.getElementById('download').appendChild(iframe)
      document.getElementById('print-iframe').contentWindow.print()
    }
    const download = (docnum, doctype, InvDetAccess) => {
      debugger
      if (InvDetAccess == 0) {
        Vue.swal({
          title: 'Belge Basılamaz!',
          text: 'E-belge Gib e gönderilmediği için basılamaz.',
          icon: 'error',
          timer: 5000,
          timerProgressBar: true,
          showConfirmButton: true,
        })

        return
      } else {
        dialog.value = true
        dialogPersistent.value = true
        dialogNum.value = docnum
        const param = {
          method: 'getInvContent',
          doctype,
          docnum,
        }
        if (userData.role === 'PLSYR') {
          param.customer = store.state.plasiyerCustomer
        }

        store
          .dispatch('postMethod', param)
          .then(response => {
            if (response.error === 0) {
              iframe.setAttribute('id', 'print-iframe')
              iframe.width = '100%'
              iframe.height = '700'
              iframe.srcdoc = ''
              iframe.srcdoc = response.content

              document.getElementById('download').appendChild(iframe)
              //document.getElementById('print-iframe').contentWindow.print()
              dialogPersistent.value = false
            } else {
              dialog.value = false
            }
          })
          .catch(error => {
            dialog.value = false
            console.log(error)
          })
      }
    }

    const dialogDetail = ref(false)
    const detailHeaders = ref([
      {
        text: 'No',
        value: 'ITEMNUM',
        width: '60',
        sortable: false,
      },
      {
        text: 'Malzeme',
        value: 'MATERIAL',
        width: '150',
        sortable: false,
      },
      {
        text: 'Ürün',
        value: 'MTEXT',
        sortable: false,
      },
      {
        text: 'Sevk Miktarı',
        value: 'sevkmiktar',
        width: '120',
        sortable: false,
        align: 'center',
      },

      {
        text: 'Birim Fiyat',
        value: 'SPRICE',
        width: '150',
        sortable: false,
        align: 'right',
      },
      {
        text: 'Tutar',
        value: 'SUBTOTAL',
        width: '150',
        sortable: false,
        align: 'right',
      },

      {
        text: 'KDV',
        value: 'VATAMNT',
        width: '150',
        sortable: false,
        align: 'right',
      },
      {
        text: 'Genel Toplam',
        value: 'GRANDTOTAL',
        width: '150',
        sortable: false,
        align: 'right',
      },
    ])
    const detailItems = ref([])
    const detail = (docnum, doctype) => {
      dialogDetail.value = true
      dialogPersistent.value = true
      dialogNum.value = docnum
      const param = {
        method: 'getCaniasOrderDetail',
        doctype,
        docnum,
      }
      if (userData.role === 'PLSYR') {
        param.customer = store.state.plasiyerCustomer
      }

      store
        .dispatch('postMethod', param)
        .then(response => {
          if (response.error === 0) {
            dialogPersistent.value = false
            detailItems.value = response.detail
            console.log(response.detail)
            response.detail.forEach(item => {
              item.AVAILQTY = Math.floor(item.AVAILQTY)
              item.QUANTITY = Math.floor(item.QUANTITY)
            })

            siparisListesi_json.value = response.detail
          } else {
            dialogDetail.value = false
          }
        })
        .catch(error => {
          dialogDetail.value = false
          console.log(error)
        })
    }

    onMounted(() => {
      const param = {
        method: 'getDocStatuses',
      }
      store
        .dispatch('postMethod', param)
        .then(response => {
          const data = []
          // eslint-disable-next-line no-restricted-syntax
          for (const value of Object.entries(response.detail)) {
            const item = {
              text: value[1],
              value: Number(value[0]),
            }
            data.push(item)
          }

          data.unshift(data.splice(data.length - 2, 1)[0])
          data.push({ text: 'Hepsi', value: '' })

          docStatusesItems.value = data
        })
        .catch(error => {
          console.log(error)
        })
    })
    const isFilterUserSidebarActive = ref(false)
    const userData = JSON.parse(localStorage.getItem('userData'))
    const msg = ref('Formu doldurduktan sonra ara butonuna basın')
    const status = ref(true)

    const filter = ref({
      startDate: new Date(Date.now() + new Date().getTimezoneOffset() * 60 * 60 * 60 * 100).toISOString().substr(0, 10),
      endDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
      docStatuses: '',
      docCustomer: '',
      docMaterial: '',
    })

    const maxDate = new Date().toISOString().slice(0, 10)

    const startMenu = ref(false)
    const endMenu = ref(false)
    const tableItems = ref([])
    const tableFooter = ref([])
    const customer = ref(null)
    const customerSearch = ref(null)
    const customerItems = ref([])
    const customerLoading = ref(false)
    const tableColumns = ref([
      {
        text: 'Tarih',
        value: 'VALIDFROM',
        sortable: false,
        width: '150px',
      },
      {
        text: 'Doküman No',
        value: 'DOCNUM',
        sortable: false,
        width: '150px',
      },

      {
        text: 'Durum',
        value: 'DOCSTATE',
        sortable: false,
        width: '150px',
      },
      {
        text: 'Genel Toplam',
        value: 'GRANDTOTAL',
        sortable: false,
        align: 'right',
        width: '150px',
      },

      {
        text: 'Adres',
        value: 'adres',
        sortable: false,
        width: '350px',
      },

      /* {
        text: 'Fatura No',
        value: 'PRINTEDINVNUM',
        sortable: false,
        width: '150px',
      }, */

      /*
      {
        text: 'Müşteri',
        value: 'NAME1',
        sortable: false,
        align: 'start min-width-400',
      },
           {
        text: 'Net Toplam',
        value: 'SUBTOTAL',
        sortable: false,
        align: 'right',
        width: '150px',
      },
      {
        text: 'Brüt Toplam',
        value: 'GROSS',
        sortable: false,
        align: 'right',
        width: '150px',
      },
      {
        text: 'Toplam İndirim',
        value: 'TOTDISCAMNT',
        sortable: false,
        align: 'right',
        width: '150px',
      },
      */

      // {
      //   text: 'Para',
      //   value: 'CURRENCY',
      //   sortable: false,
      //   align: 'right',
      //   width: '70px',
      // },

      {
        text: '',
        value: 'actions',
        align: 'right',
        sortable: false,
        width: '300px',
      },
    ])
    const totalDocumentsListTable = ref(0)
    const loading = ref(false)
    const options = ref({
      sortBy: ['id'],
      sortDesc: [true],
    })
    const selectedRows = ref([])
    const json_data = ref([])
    const siparisListesi_json = ref([])

    const fetchDocuments = () => {
      isFilterUserSidebarActive.value = false
      tableItems.value = []
      tableFooter.value = []
      msg.value = 'Arama yapılıyor lütfen bekleyin'
      loading.value = true
      const fetchDocumentsData = {
        method: 'getCaniasOrders',
        'search[startDate]': filter.value.startDate,
        'search[endDate]': filter.value.endDate,
        'search[orderType]': docTypes.value,
        'search[orderStatus]': filter.value.docStatuses,
        'search[material]': filter.value.docMaterial,
        'search[customer]': data1.value === null ? '' : data1.value,
      }

      store
        .dispatch('app-documents/fetchDocuments', fetchDocumentsData)
        .then(response => {
          if (response.detail) {
            tableItems.value = response.detail
            json_data.value = response.detail

            totalDocumentsListTable.value = Number(response.detail.length)
            loading.value = false
            status.value = false
          } else {
            loading.value = false

            Vue.swal({
              title: 'Veri Yok',
              width: '350px',
              icon: 'warning',
              background: '#FFB400',
              showCloseButton: false,
              showConfirmButton: true,
              confirmButtonColor: '#987c3d',
            })
            msg.value = 'Formu doldurtuktan sonra ara butonuna basın'
            status.value = true
          }
        })
        .catch(e => {
          loading.value = false
          Vue.swal({
            title: 'Hata',
            text: e.message === "Cannot read properties of null (reading 'length')" ? 'Sistem Hatası' : e.message,
            width: '350px',
            icon: 'error',
            background: '#FF4C51',
            showCloseButton: false,
            showConfirmButton: true,
            confirmButtonColor: '#cc3d41',
          })
          status.value = true
        })
    }
    watch(dialogDetail, val => {
      if (!val) {
        dialogPersistent.value = true
        detailItems.value = []
      }
    })

    const title = ref('')

    const titleFn = () => {
      if (route.value.params.folder === 'order') {
        title.value = 'Sipariş Listesi'
        msg.value = 'Formu doldurduktan sonra ara butonuna basın'
        docTypes.value = 1
      } else if (route.value.params.folder === 'waybill') {
        title.value = 'İrsaliye Listesi'
        msg.value = 'Formu doldurduktan sonra ara butonuna basın'
        docTypes.value = 3
      } else if (route.value.params.folder === 'bill') {
        title.value = 'Fatura Listesi'
        msg.value = 'Formu doldurduktan sonra ara butonuna basın'
        docTypes.value = 4
      }
    }

    function isStatx(array) {
      return array.value === 'STATX'
    }
    function isINVOICE(array) {
      return array.value === 'PRINTEDINVNUM'
    }
    function isPRINTEDDELNUM(array) {
      return array.value === 'PRINTEDDELNUM'
    }

    onMounted(() => {
      /*
      if (route.value.params.folder == 'bill') {
        detailHeaders.value[3] = {
          text: 'Miktar',
          value: 'QUANTITY',
          width: '120',
          sortable: false,
          align: 'center',
        }
      } else {
        detailHeaders.value[3] = {
          text: 'Sevk Miktarı',
          value: 'sevkmiktar',
          width: '120',
          sortable: false,
          align: 'center',
        }
      }
*/
      debugger
      if (route.value.params.folder === 'bill') {
        if (!tableColumns.value.find(isINVOICE)) {
          tableColumns.value.splice(1, 0, {
            text: 'Fatura No',
            value: 'PRINTEDINVNUM',
            sortable: false,
            width: '90px',
          })
        }
      } else if (tableColumns.value.find(isINVOICE)) {
        tableColumns.value.splice(1, 1)
      }

      if (route.value.params.folder === 'waybill') {
        if (!tableColumns.value.find(isPRINTEDDELNUM)) {
          tableColumns.value.splice(1, 0, {
            text: 'İrsaliye No',
            value: 'PRINTEDDELNUM',
            sortable: false,
            width: '90px',
          })
        }
      } else if (tableColumns.value.find(isPRINTEDDELNUM)) {
        tableColumns.value.splice(1, 1)
      }

      if (route.value.params.folder !== 'order' && route.value.params.folder !== 'waybill') {
        if (!tableColumns.value.find(isStatx)) {
          tableColumns.value.splice(3, 0, {
            text: 'Statü',
            value: 'STATX',
            sortable: false,
            width: '90px',
          })
          detailHeaders.value[3] = {
            text: 'Miktar',
            value: 'QUANTITY',
            width: '120',
            sortable: false,
            align: 'center',
          }
        }
      } else if (tableColumns.value.find(isStatx)) {
        tableColumns.value.splice(2, 1)
      } else {
        detailHeaders.value.splice(3, 0, {
          text: 'Sipariş Miktarı',
          value: 'QUANTITY',
          sortable: false,
          width: '90px',
        })
        detailHeaders.value.splice(5, 0, {
          text: 'Bakiye Miktarı',
          value: 'AVAILQTY',
          sortable: false,
          width: '90px',
          align: 'center',
        })
        detailHeaders.value[4] = {
          text: 'Sevk Miktarı',
          value: 'sevkmiktar',
          width: '120',
          sortable: false,
          align: 'center',
        }
      }
    })

    watch(
      () => route.value.params.folder,
      () => {
        debugger
        if (route.value.params.folder !== 'order') {
          if (!tableColumns.value.find(isStatx)) {
            tableColumns.value.splice(3, 0, {
              text: 'Statü',
              value: 'STATX',
              sortable: false,
              width: '90px',
            })
          }
        } else if (tableColumns.value.find(isStatx)) {
          tableColumns.value.splice(3, 1)
        }

        if (route.value.params.folder === 'order') {
          detailHeaders.value.splice(3, 0, {
            text: 'Sipariş Miktarı',
            value: 'QUANTITY',
            sortable: false,
            width: '90px',
          })
          detailHeaders.value.splice(5, 0, {
            text: 'Bakiye Miktarı',
            value: 'AVAILQTY',
            sortable: false,
            width: '90px',
          })
          detailHeaders.value[4] = {
            text: 'Sevk Miktarı',
            value: 'sevkmiktar',
            width: '120',
            sortable: false,
            align: 'center',
          }
        } else if (
          detailHeaders.value[3].text === 'Sipariş Miktarı' &&
          detailHeaders.value[5].text === 'Bakiye Miktarı'
        ) {
          detailHeaders.value.splice(3, 1)
          detailHeaders.value.splice(4, 1)
          detailHeaders.value[3] = {
            text: 'Miktar',
            value: 'QUANTITY',
            width: '120',
            sortable: false,
            align: 'center',
          }
        }
        debugger
        if (route.value.params.folder === 'bill') {
          if (!tableColumns.value.find(isINVOICE)) {
            tableColumns.value.splice(1, 0, {
              text: 'Fatura No',
              value: 'PRINTEDINVNUM',
              sortable: false,
              width: '90px',
            })
          }
        } else if (tableColumns.value.find(isINVOICE)) {
          tableColumns.value.splice(1, 1)
        }

        if (route.value.params.folder === 'waybill') {
          if (!tableColumns.value.find(isPRINTEDDELNUM)) {
            tableColumns.value.splice(1, 0, {
              text: 'İrsaliye No',
              value: 'PRINTEDDELNUM',
              sortable: false,
              width: '90px',
            })
          }
        } else if (tableColumns.value.find(isPRINTEDDELNUM)) {
          tableColumns.value.splice(1, 1)
        }

        titleFn()
        status.value = true
      },
    )
    onMounted(() => {
      titleFn()
    })

    const STATXcolor = val => {
      let result
      if (val === 'Açık') {
        result = 'error'
      } else if (val === 'Kapalı') {
        result = 'success'
      } else {
        result = 'warning'
      }

      return result
    }
    const filterClear = () => {
      isFilterUserSidebarActive.value = false
      filter.value = {
        id: '',
        aktif: '',
        baslik: '',
        urun_kodu: '',
        plant: '',
      }
      Vue.swal({
        title: 'Temizlendi',
        text: 'Filitre Temizlendi',
        icon: 'success',
        timer: 1500,
        timerProgressBar: true,
        showConfirmButton: false,
      })
    }
    onMounted(() => {
      store
        .dispatch('app/fetchCustomersBySalDept')
        .then(response => {
          customerItems.value = response.detail
        })
        .catch(error => {
          console.log(error)
        })
    })

    return {
      dialogDetail,
      detail,
      detailItems,
      detailHeaders,
      isFilter,

      filterClear,
      statusPlasiyer,
      title,
      customerItems,
      customerSearch,
      customerLoading,
      customer,
      isDark,
      tableColumns,
      tableItems,
      json_data,
      siparisListesi_json,
      tableFooter,
      loading,
      selectedRows,
      options,
      dialogNum,
      startMenu,
      endMenu,
      msg,
      data1,
      status,
      userData,
      dialog,
      totalDocumentsListTable,
      fetchDocuments,
      isFilterUserSidebarActive,
      docTypesItems,
      docTypes,
      docStatusesItems,
      filter,
      download,
      belgeIndir,
      dialogPersistent,
      STATXcolor,

      // icons
      icons: {
        mdiDownload,
        mdiFilter,
        mdiViewList,
        mdiNoteText,
        mdiSend,
        mdiEye,
        mdiFileDocument,
        mdiClose,
      },
    }
  },

  data() {
    return {
      dataTableHeight: '',
      height: {
        system: 0,
        top: this.$vuetify.breakpoint.smAndDown ? (this.userData.role === 'PLSYR' ? 140 : 48) : 100,
        footer: this.$vuetify.breakpoint.smAndDown ? 47 : 47,
      },
      json_fields: {
        Tip: 'DOCTYPEX',
        'Doküman No': 'DOCNUM',

        Statü: 'STATX',
        Tarih: 'VALIDFROM',
        Para: 'CURRENCY',
        'Net Toplam': 'SUBTOTAL',
        'Brüt Toplam': 'GROSS',
        'Toplam İndirim': 'TOTDISCAMNT',
        'Genel Toplam': 'GRANDTOTAL',
      },

      siparisListesi_jsonFields: {
        No: 'ITEMNUM',
        Malzeme: 'MATERIAL',
        Ürün: 'MTEXT',
        'Sipariş Miktarı': 'QUANTITY',
        'Sevk Miktarı': 'sevkmiktar',
        'Bakiye Miktarı ': 'AVAILQTY',
        'Birim Fiyat': 'SPRICE',
        Tutar: 'SUBTOTAL',
        KDV: 'VATAMNT',
        'Genel Toplam': 'GRANDTOTAL',
      },

      json_meta: [
        [
          {
            key: 'charset',
            value: 'utf-8',
          },
        ],
      ],
    }
  },

  mounted() {
    this.$nextTick(() => {
      this.onResize()
      window.addEventListener('resize', this.onResize)
    })
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },

  methods: {
    onResize() {
      this.dataTableHeight = window.innerHeight - 90 - this.height.top - this.height.footer - 1
    },
  },
}
</script>

<style>
.v-navigation-drawer .v-list-item:not(.v-list-item--active) .v-list-item__title {
}
.pdng {
  padding-bottom: 85px;
}
</style>
